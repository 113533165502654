export const addScriptTag = (id, src) => {
  if (!id) return null;

  const scriptTagId = `script-tag-${id}`;
  const exists = document.getElementById(scriptTagId);
  if (exists) return null;

  const script = document.createElement("script");
  script.src = src;
  script.id = scriptTagId;
  script.async = true;
  document.body.appendChild(script);
};
