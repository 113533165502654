import React, { useEffect, useState } from "react";
import { addScriptTag } from "src/helpers/script";

import "./YoutubeVideo.scss";

const YOUTUBE_JS_CDN = "//www.youtube.com/iframe_api";

// const STATE_UNSTARTED = -1; // (unstarted)
const STATE_ENDED = 0; // (ended)
const STATE_PLAYING = 1; // (playing)
const STATE_PAUSED = 2; // (paused)
// const STATE_BUFFERING = 3; // (buffering)
// const STATE_CUED = 5; // (video cued).

const SECOND_IN_MS = 1000;
let player = null;

const YoutubeVideo = ({
  id,
  playerVars = {
    playsinline: 1,
    controls: 1,
    disablekb: 0,
    location: null,
    autoplay: 0,
  },
  getVideoInfo = () => {},
}) => {
  let intervalId = null;
  const [info, setInfo] = useState({ ready: false });
  const playerId = `youtube-player-${id}`;

  useEffect(() => {
    if (!id) return;
    addScriptTag("ytjs", YOUTUBE_JS_CDN);

    window.onYouTubeIframeAPIReady = () => {
      const newInfo = { ...info, ready: true };
      setInfo(newInfo);
      getVideoInfo(newInfo);
    };

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [info, id, intervalId, getVideoInfo]);

  if (!id) return null;

  const events = {
    onStateChange: ({ data }) => {
      console.log("onStateChange", data);
      const shouldClearInterval = data === STATE_PAUSED || data === STATE_ENDED;
      const isPlaying = data === STATE_PLAYING;
      if (isPlaying) {
        intervalId = setInterval(() => {
          const time = Math.floor(player.playerInfo.currentTime);
          const newInfo = { ...info, currentTime: time };
          setInfo(newInfo);
          getVideoInfo(newInfo);
        }, SECOND_IN_MS);
      } else if (shouldClearInterval) {
        if (intervalId) clearInterval(intervalId);
      }
    },
  };

  if (info.ready && !player) {
    player = new window.YT.Player(playerId, {
      videoId: id,
      width: "100%",
      height: "100%",
      events,
      title: "Video",
      playerVars,
      location: playerVars.location || null,
    });
  }

  const style = {
    backgroundImage: `url(https://i3.ytimg.com/vi/${id}/maxresdefault.jpg)`,
  };

  const wrapper = info.ready ? `videoWrapper ready` : `videoWrapper`;

  return (
    <div className="youtube">
      <div className={wrapper} style={style}>
        <div id={playerId} />
      </div>
    </div>
  );
};

export default YoutubeVideo;
