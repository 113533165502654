import React from "react";

import SEO from "src/components/SEO";

import YoutubeVideo from "../../components/YoutubeVideo";
import { NAME, BASE_URL } from "src/helpers/config";
import "src/sections/Maratona-JS-2/maratona-js-2.scss";

const MaratonaJS = () => {
  const seoProps = {
    title: `Promo - 1º de Abril 2021 - ${NAME}`,
    description: "Promo - 1º de Abril 2021 - ",
    canonical: `${BASE_URL}/promo/`,
    image: `${BASE_URL}/meta/og-image-maratona-js-2.jpg`,
  };

  return (
    <div className="maratona-js" style={{ minHeight: "100vh" }}>
      <SEO {...seoProps} />
      <div className="wrapper">
        <h1 style={{ textAlign: "center" }}>1º de Abril</h1>
        <p style={{ textAlign: "center" }}>
          Compartilhe a postagem do instagram, ou se quiser a promoção, fica $1
          pra assitir o video abaixo.
        </p>
        <div style={{ margin: "0 auto", maxWidth: "960px" }}>
          <YoutubeVideo id="DW6DTHVtgiE" playerVars={{ autoplay: 1 }} />
        </div>
      </div>
    </div>
  );
};

export default MaratonaJS;
